import { Controller } from "@hotwired/stimulus";

export default class ImagePreviewController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    this._loadImage();
    this.element.addEventListener("change", () => {
      this._loadImage();
    });
  }

  _loadImage() {
    const previewBox = document.querySelector(".js-image-preview");

    const img = document.createElement("img");
    img.src = this.element.dataset.src;
    img.alt = "preview";
    previewBox.innerHTML = "";
    previewBox.appendChild(img);
  }
}
