import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addOption"]

  connect() {
    console.log('Connecting...');
    window.addEventListener('option:update', this.updateAddOptionState.bind(this));
  }

  disconnect() {
    window.removeEventListener('option:update', this.updateAddOptionState.bind(this));
  }

  updateAddOptionState(event) {
    console.log('Updating add button state...');
    console.log('Number of options:', event.detail.count);
  
    if (this.addOptionTarget) {
      if (event.detail.count >= 3) {
        console.log('Disabling add button...');
        this.addOptionTarget.setAttribute('disabled', 'disabled');
      } else {
        console.log('Enabling add button...');
        this.addOptionTarget.removeAttribute('disabled');
      }
    }
  }
}