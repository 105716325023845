import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["optionValue", "addButton"]

  connect() {
    this.initializeTargets();
    this.startObserving();
  }

  disconnect() {
    this.observer.disconnect();
  }

  initializeTargets() {
    this.optionValueTargets.forEach((target) => {
      if (target.dataset.keyupListenerAttached !== "true") {
        target.dataset.modified = "false";
        target.addEventListener('keyup', this.handleKeyup.bind(this));
        target.dataset.keyupListenerAttached = "true";
      }
    });
  }

  startObserving() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE && node.matches('[data-value_target="optionValue"]')) {
              this.initializeTarget(node);
            }
          });
        }
      });
    });
  
    this.observer.observe(document, { childList: true, subtree: true });
  }

  handleKeyup(event) {
    if (event.target.dataset.modified === "false") {
      const newValue = event.target.value;
      const previousValues = this.optionValueTargets.map((target) => target.value).filter((value) => value !== newValue);
      if (newValue !== '' && !previousValues.includes(newValue)) {
        const wrapper = event.target.closest('.value-fields-wrapper');
        const addButton = wrapper.querySelector('.add_button.add_value');
        addButton.click();
  
        // Add a delay before initializing new targets
        setTimeout(() => {
          this.initializeTargets();
        }, 100);
      }
      event.target.dataset.modified = "true";
    }
  }
}