import { Controller } from "@hotwired/stimulus";

export default class SidebarController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    this.element.addEventListener('click', (event) => {
      const sidebar = document.querySelector('.js-sidebar');

      if (sidebar) {
        sidebar.classList.toggle('is-closed');
      }
    });
  }
}
