import { Controller } from "@hotwired/stimulus";

export default class FontImportController extends Controller {
  connect() {
    this._init();
  }

  _init() {
    this._loadFont();
    // when element is changed, load font
    this.element.addEventListener("change", () => {
      this._loadFont();
    });
  }

  _loadFont() {
    const previewBox = document.querySelector(".js-font-preview");
    previewBox.querySelector("h4").style.fontFamily = this.element.dataset.headingFont;
    previewBox.querySelector("p").style.fontFamily = this.element.dataset.bodyFont;
  }
}
