


import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "variantFieldsWrapper", "addVariant"];

  handleButtonClick(event) {
    console.log('handleButtonClick called');
    
    // Get all product options
    let productOptions = this.optionTargets;
    console.log('productOptions:', productOptions);
    
    let productOptionValues = Array.from(productOptions).map(option => {
      // Get all values for each product option
      let values = option.querySelectorAll('.option-value-field input');
      return Array.from(values).map(value => {
        console.log('Value:', value.value);  // Log each value
        return value.value;
      }).filter(value => value.trim() !== '');  // Ignore empty values
    });
    
    console.log('productOptionValues:', productOptionValues);
    
    // Generate all combinations of product option values
    let combinations = this.cartesianProduct(productOptionValues);
    console.log('combinations:', combinations);
    
    // Add nested fields to the form using your custom Rails helpers
    this.addNestedFields(combinations);
  }
  
  cartesianProduct(arr) {
    return arr.reduce(function(a, b) {
      return a.map(function(x) {
        return b.map(function(y) {
          return x.concat(y);
        })
      }).reduce(function(a, b) { return a.concat(b) }, [])
    }, [[]])
  }

  addNestedFields(combinations) {
    const wrapper = this.variantFieldsWrapperTarget;
  
    this.addVariantFieldsUsingRailsHelpers(combinations, wrapper, 0);
  }
  
  addVariantFieldsUsingRailsHelpers(combinations, wrapper, index) {
    if (index >= combinations.length) {
      return;
    }
  
    const combination = combinations[index];
    const combinationString = combination.join(' / ');
  
    const existingField = Array.from(wrapper.querySelectorAll('.variant-fields input[name$="[title]"]')).find(input => input.value === combinationString);
  
    if (!existingField) {
      const addButton = wrapper.querySelector('.add_button.add_variant');
      if (addButton) {
        addButton.click();
        console.log("addVariantFieldsUsingRailsHelpers called");
  
        setTimeout(() => {
          const newFieldset = wrapper.querySelector('fieldset:last-of-type');
  
          const titleField = newFieldset.querySelector('input[name$="[title]"]');
          if (titleField) {
            titleField.value = combinationString;
          }
  
          combination.forEach((value, index) => {
            const optionField = newFieldset.querySelector(`input[name$="[option${index + 1}]"]`);
            if (optionField) {
              optionField.value = value;
            }
          });
  
          this.addVariantFieldsUsingRailsHelpers(combinations, wrapper, index + 1);
        }, 0);
      } else {
        console.log("No add button found");
      }
    } else {
      console.log(`A field with the combination "${combinationString}" already exists`);
  
      let fieldset = existingField.closest('fieldset');
      if (fieldset.style.display === 'none') {
        fieldset.style.display = '';
        console.log(`The fieldset with the combination "${combinationString}" was hidden and has been unhidden`);
  
        let hiddenField = fieldset.querySelector('input[type=hidden][name$="[_destroy]"]');
        if (hiddenField) {
          hiddenField.value = 0;
          console.log(`The hidden field in the fieldset with the combination "${combinationString}" has been set to 0`);
        }
      }
  
      this.addVariantFieldsUsingRailsHelpers(combinations, wrapper, index + 1);
    }
  }
}