window.dispatchModal = (content, options = {}) => {
  setModalSize(options.size);
  setModalContent(content, options);
  openModal(options);

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal dispatched`);
  }
}

window.setModalSize = (size = 'medium') => {
  let modalWrapper = document.querySelector('.modal__wrapper');
  modalWrapper.classList.remove('modal__wrapper--small', 'modal__wrapper--medium', 'modal__wrapper--large');
  modalWrapper.classList.add(`modal__wrapper--${size}`);
}

window.setModalContent = (content, options = {}) => {
  let modalContent = document.querySelector('.js-modal-content');
  modalContent.innerHTML = content;
  modalContent.classList.remove('is-no-padding');

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal content set:`, content);
  }

  if (options.no_padding) {
    modalContent.classList.add('is-no-padding');
  } 
}

window.openModal = (options = {}) => {
  let modal = document.querySelector('.js-modal');
  let modalContent = document.querySelector('.js-modal-content');
  document.body.classList.add('modal-is-open');

  modal.hidden = false;
  modalContent.focus();

  if($(modalContent).find('input') && !options.nofocus){
    $(modalContent).find('input:visible, select, textarea:visible').first().focus();
  }

  if (!options.prevent_close) {
    document.onkeydown = (e) => {
      e = e || window.event;
      if (e.key === "Escape" || e.key === 27) {
        closeModal();
      }
    }
  } else {
    $('.modal__close').remove();
  }

  document.querySelector('.modal__wrapper').classList.add('zoom-in');
  document.querySelector('.modal').scrollTop = 0;

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal is open`);
  }
}

window.closeModal = () => {
  let modal = document.querySelector('.js-modal');
  document.body.classList.remove('modal-is-open');
  document.querySelector('.modal__wrapper').classList.remove('zoom-in');

  modal.hidden = true;

  document.onkeydown = null;

  if (isDevEnvironment()) {
    console.info(`[DEV] Modal is closed`);
  }
}
