import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "addOption"]

connect() {
  console.log('Connecting...');

  // Check if the "addOption" target exists before calling updateOptionLimit

  this.updateOptionLimit();


  // Start observing the document with the configured mutation observer
  this.observer.observe(this.element, { childList: true, subtree: true });

  const addOptionTarget = document.querySelector('.add-option-button');
  if (addOptionTarget) {
    // Add the handleAddOptionClick event listener to the addOptionTarget
    addOptionTarget.addEventListener('click', this.handleAddOptionClick.bind(this));
  }
}

  disconnect() {
    // Stop observing the document
    this.observer.disconnect();
  }

  // Create a mutation observer to watch for changes to the DOM
  observer = new MutationObserver((mutationsList, observer) => {
    for(let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        // Check if the added node is an option
        for(let node of mutation.addedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE && node.matches('[data-option-target="option"]')) {
            // An option was added, update the option limit
            this.updateOptionLimit();
          }
        }
        // Check if an option was removed
        for(let node of mutation.removedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE && node.matches('[data-option-target="option"]')) {
            // An option was removed, update the option limit
            this.updateOptionLimit();
          }
        }
      }
    }
  });

  updateOptionLimit() {
    console.log('Updating option limit...');
    console.log('Number of options:', this.optionTargets.length);
   
    // Access the addOptionTarget using document.querySelector
    const addOptionTarget = document.querySelector('.add-option-button');
    if (addOptionTarget) {
      if (this.optionTargets.length >= 3) {
        console.log('Disabling add button...');
        addOptionTarget.classList.add('disabled');

        
      } else {
        console.log('Enabling add button...');
        addOptionTarget.classList.remove('disabled');

      }
    }
  }


  handleAddOptionClick(event) {
    // Access the addOptionTarget using event.currentTarget
    const addOptionTarget = event.currentTarget;

    // Check if the addOptionTarget has the disabled class
    if (addOptionTarget.classList.contains('disabled')) {
      // Prevent the default action of the click event
      event.preventDefault();
      // Stop the event from propagating up to parent elements
      event.stopPropagation();
    }
  }
}